import React from 'react';
import FAQQuestion from '../shared/landing-pages/FAQQuestion';

export default function FAQQuestions() {
  return (
    <div className="faq-questions">
      <div className="faq-questions__item">
        <FAQQuestion
          label="How does Kajabi Experts work?"
        >
          <p className="t-sage-body t-sage--color-charcoal-200">We’ll ask you a set of questions to help us create an outline for your project that will become your project brief. We’ll then send your project brief to matching top-rated service providers inside the Kajabi Experts marketplace.</p>
          <br />
          <p className="t-sage-body t-sage--color-charcoal-200">These service providers can message you inside Kajabi Experts to ask questions about your requirements and submit proposals to you with a quoted price. Once you accept a Kajabi Expert’s quote, the project becomes active, and the Kajabi Expert is committed to completing the agreed-upon scope of work. Your acceptance of the quote acts as your contract with the service provider, so be sure to have them include any terms that may be important to you in the quote.</p>
          <br />
          <p className="t-sage-body t-sage--color-charcoal-200">When the work is done to your satisfaction, you’ll mark the project complete, rate the Kajabi Expert, and submit a review of your experience. You’ll receive an invoice from Kajabi for the work via email.</p>
        </FAQQuestion>
      </div>
      <div className="faq-questions__item">
        <FAQQuestion
          label="Why should I use Kajabi Experts to hire help instead of other marketplaces?"
        >
          <p className="t-sage-body t-sage--color-charcoal-200">Service providers on Kajabi Experts are vetted to be among the best Kajabi-focused professionals in their field. In addition, your transaction on Kajabi Experts is protected—if you experience any problems that you are unable to resolve with the service provider, Kajabi will provide you with personalized support.</p>
        </FAQQuestion>
      </div>
      <div className="faq-questions__item">
        <FAQQuestion
          label="How do I know the service providers on Kajabi Experts are qualified?"
        >
          <p className="t-sage-body t-sage--color-charcoal-200">During our Kajabi Experts vetting process, we look for each service provider’s track record of success in helping clients use Kajabi to develop their businesses, and we review their reputation as a service provider to Kajabi customers. Only providers that meet our rigorous criteria are accepted into the Kajabi Experts platform.</p>
        </FAQQuestion>
      </div>
    </div>
  );
}
