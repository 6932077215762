/* eslint-disable import/prefer-default-export */

export const siteFooterData = {
  sections: [
    {
      id: 1,
      title: 'Projects',
      links: [
        {
          href: '/projects/new',
          title: 'New Project',
        },
        {
          href: '/projects',
          title: 'My Projects',
        },
        {
          href: '/messages',
          title: 'My Message Inbox',
        },
        {
          href: 'https://kajabi.com/pricing',
          title: 'Sign Up For Kajabi',
        },
      ],
    },
    {
      id: 2,
      title: 'Services',
      links: [
        {
          href: '/projects/new/website-setup-design',
          title: 'Website Setup & Design',
        },
        {
          href: '/projects/new/marketing-sales',
          title: 'Marketing & Sales',
        },
        {
          href: '/projects/new/admin-support',
          title: 'Admin Support',
        },
        {
          href: '/projects/new/content-development',
          title: 'Content Development',
        },
        {
          href: '/projects/new/visual-design-branding',
          title: 'Visual Design & Branding',
        },
        {
          href: '/directory',
          title: 'Expert Directory',
        },
      ],
    },
    {
      id: 3,
      title: 'Experts',
      links: [
        {
          href: 'https://kajabi.com/policies/experts-marketplace-agreement',
          title: 'Marketplace Agreement',
        },
      ],
    },
    {
      id: 4,
      title: 'Support',
      links: [
        {
          href: 'mailto:experts@kajabi.com',
          title: 'Contact Experts Team',
        },
        {
          href: 'https://help.kajabi.com/hc/en-us/sections/12496067196187-Kajabi-Experts',
          title: 'Help Center',
        },
        {
          href: 'https://kajabi.com/policies/experts-marketplace-terms',
          title: 'Marketplace Terms',
        },
      ],
    },
  ],
};
