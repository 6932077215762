import React from 'react';
import {
  Link,
} from '@kajabi/sage-react';
import Directory from './Directory';
import LogIn from './LogIn';
import Templates from './Templates';

export default function ActionsLoggedOut() {
  return (
    <>
      <Directory />
      <Templates />
      <Link
        href="https://kajabi.com/pricing"
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        Sign Up For Kajabi
      </Link>
      <LogIn />
    </>
  );
}
